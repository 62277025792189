//WFHApproval.jsx

import React, { useState, useEffect } from "react";
import logger from "./logs/logger";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./styles/ApprovalPage.css";
import "./styles/PopUp.css";
import { API_BASE_URL } from "./constant/apiConstants";
import { UserHeaders } from "./constant/localStorageConstants";
import { useLocation } from 'react-router-dom';
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import { HttpStatusCode } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from './Utils/ConvertDate.jsx';

const WFHApproval = ({ userId }) => {
  const [approvalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(5);
  const [showPopup, setShowPopup] = useState(false);
  const [ selectedLeave, setSelectedLeave] = useState({});
  const [comments, setComments] = useState("");
  const [status, setStatus] = useState("");
  const [hasApprovalRights, setHasApprovalRights] = useState(false);
  const navigate = useNavigate();
  const [wfhRequest, setWFHRequest] = useState([]);
  const location = useLocation();
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);

  const fetchData = async () => {
    let wfhRequest;
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);
      const response = await fetch( `${API_BASE_URL}/wfhDetails/${UserHeaders.USERID.value}`,{
        method: 'POST',
        headers: {
           'Content-Type': 'application/json',
            ...getAuthHeaders(accessToken),
        },
      });

      if (response.status === HttpStatusCode.NoContent) {
        logger.warn("Work-from-home approval Not Found: User with name " + UserHeaders.USERNAME.value + " does not have any WFH approval.");
         return;
      }

      if (response.ok) {
        wfhRequest = await response.json();
        if (Array.isArray(wfhRequest.message.WfhDetails)) {
          const sortedWFHDetails = wfhRequest.message.WfhDetails.sort(
            (a, b) => new Date(b.createdTime) - new Date(a.createdTime)
          );
          setWFHRequest(sortedWFHDetails);
        } else {
           logger.error("User Wfh Details is not a valid array:", wfhRequest);
        }
      } else {
        logger.error("Error fetching WFHApproval data:", response.status);
      }
    } catch (error) {
      logger.error("An error occurred while fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, [accessToken, userId]);

  const handleApproveRejectSubmit = async (event) => {
    event.preventDefault();
    logger.info("Selected Leave:", selectedLeave);
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);
      const response = await fetch( `${API_BASE_URL}/edit-wfh/${selectedLeave.id}`,{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...getAuthHeaders(accessToken),
          },
          body: JSON.stringify({
            comments: comments,
            status: status,
            approvalId: UserHeaders.USERROLE.value,
          }),
        }
      );

      if (response.ok) {
        setShowPopup(false);
        setSelectedLeave({});
        setComments("");
        setStatus("");
        fetchData();
      } else {
        logger.error("Error updating leave:", await response.json());
      }
    } catch (error) {
      logger.error("An error occurred during the API call:", error.message);
    }
  };

  // Logic to display items based on pagination
  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(wfhRequest.length / itemsPerPage);
  const currentItems = wfhRequest.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleButtonClick = (actionType, user) => {
    setComments("");
    setStatus(actionType.toUpperCase());
    setSelectedLeave(user);

    // Update approvalId based on user role ID
    if (hasApprovalRights || UserHeaders.USERROLE.value === 1 || UserHeaders.USERROLE.value===2) {
      logger.info("userRole:", UserHeaders.USERROLE.value);
      user.approvalId = UserHeaders.USERROLE.value;
      setShowPopup(true);
    } else {
      user.approvalId = null;
      logger.info("userRole:", UserHeaders.USERROLE.value);
    }
  };

  useEffect(() => {
    const fetchApprovalRights = async () => {
      try {
        const response = await fetch( `${API_BASE_URL}/checkApprovalRights?userId=${UserHeaders.USERID.value}`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
             ...getAuthHeaders(accessToken),
          },
        });
        if (response.ok) {
          const data = await response.json();
          setHasApprovalRights(data);
        } else {
          logger.error("Error checking approval rights:", response.status);
        }
      } catch (error) {
        logger.error("An error occurred while checking approval rights:", error.message);
      }
    };
    fetchApprovalRights();
  }, [accessToken, userId]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const action = params.get('action');
    const token = localStorage.getItem('accessToken');
    const isLoggedInLocal = !!token;

    if (action === 'redirect') {
      if (isLoggedInLocal) {
        navigate('/home/WFHapproval');
      } else {
        localStorage.setItem('redirectUrl', '/home/WFHapproval');
        navigate('/login');
      }
    }
  }, [location.search, navigate]);

  return (
    <div className="wfh-approval-container">
      <div>
        <table className="wfh-approval-table">
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>WFH Date</th>
              <th>No of Days</th>
              <th>Reason</th>
              <th>Comments</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {currentItems.map((user, index) => (
              <tr key={index}>
                <td>{user.user ? user.user.username : ""}</td>
                <td>{formatDate(user.wfhDate)}</td>
                <td>{user.no_of_days}</td>
                <td>{user.reason}</td>
                <td>{user.comments}</td>
                <td>
                  {user.status && user.status.name !== null ? (
                    <div>{user.status.name}</div>
                  ) : (
                    <div>
                      {hasApprovalRights &&
                        user.approval_id == null &&
                        user.status?.name !== "Approved" &&
                        user.status?.name !== "Rejected" && (
                          <div>
                            <button className="wfh-approval-approve-button button-margin-right"
                              onClick={() => {
                                setStatus("APPROVED");
                                handleButtonClick("APPROVED", user);
                              }}>
                              Approve
                            </button>
                            <button className="wfh-approval-reject-button"
                              onClick={() => {
                                setStatus("REJECTED");
                                handleButtonClick("REJECTED", user);
                              }}>
                              Reject
                            </button>
                          </div>
                        )}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <ReactPaginate
          previousLabel={ <>
            <FontAwesomeIcon icon={faArrowLeft} className="icon-left" />
            <span className="icon-pagination">Previous</span>
          </>}
          nextLabel={ <>
            <div className="next-container">
              <span className="icon-pagination">Next</span>
              <FontAwesomeIcon icon={faArrowRight} className="icon-right" />
            </div>
          </>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination-req"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          previousClassName={currentPage === 0 ? "disabled-button" : ""}
          nextClassName={currentPage === pageCount - 1 ? "disabled-button" : ""}
        />
      </div>
      <Popup
        open={showPopup}
        closeOnDocumentClick
        onClose={() => setShowPopup(false)}
        modal
        nested
        contentClassName="popup-content"
      >
        {(close) => (
          <div className="popup-container">
            <h3 className="popup-title">Update WFHApproval Status</h3>
            <label className="popup-label">
              Comments:
              <input className="popup-input input-style" type="text" value={comments} onChange={(e) => setComments(e.target.value)} />
            </label>
            <div className="popup-buttons">
              <button className="popup-submit" onClick={handleApproveRejectSubmit}>
                Submit
              </button>
              <button className="popup-close" onClick={close}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};
export default WFHApproval;