//LeaveApproval.jsx

import React, { useState, useEffect } from "react";
import logger from "./logs/logger";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./styles/ApprovalPage.css";
import "./styles/PopUp.css";
import { API_BASE_URL } from "./constant/apiConstants";
import { UserHeaders } from "./constant/localStorageConstants";
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from './Utils/ConvertDate.jsx';

const LeaveApproval = ({ leaveApprovalData, onEdit }) => {
  const [approvalData, setApprovalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(5);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState({});
  const [comments, setComments] = useState("");
  const [status, setStatus] = useState("");
  const [hasApprovalRights, setHasApprovalRights] = useState(false);
  const [managerIds, setManagerIds] = useState([]);
  const [fileErrorMessage, setFileErrorMessage]=useState("");
  const [successMessage, setSuccessMessage]=useState("");
  const [uploadedFiles, setUploadedFiles] = useState({});

  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(
    UserHeaders.ACCESSTOKEN.value
  );

  useEffect(() => {
          const fetchApprovalRights = async () => {
              try {
                const response = await fetch( `${API_BASE_URL}/checkApprovalRights?userId=${UserHeaders.USERID.value}`,{
                  method: 'POST',
                  headers: {
                       ...getAuthHeaders(accessToken),
                  },
                });
                  if (response.ok) {
                      /* logger.info('Response for CheckApprovalRights',response); */
                      const data = await response.json();
                      /* logger.info('Data for CheckApprovalRights',data); */
                      setHasApprovalRights(data);
                  } else {
                      logger.error("Error checking approval rights:", response.status);
                  }
              } catch (error) {
                  logger.error("An error occurred while checking approval rights:", error.message);
              }
          };

          fetchApprovalRights();
      }, [accessToken]);

  const fetchData = async () => {
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);
      const response = await fetch( `${API_BASE_URL}/leaveDetails/${UserHeaders.USERID.value}`,{
          method: 'POST',
          headers: {
            ...getAuthHeaders(accessToken),
          },
      });
      let data = {};
      if (response.ok) {
      const leaveData = await response.json();
       // logger.info("fetching data:", data);
      const   data = leaveData.message;
        let sortedData = [];
        let managerIds = [];

        if (Array.isArray(data.leaveDetails)) {
          sortedData = data.leaveDetails
            .filter((leave) => leave.user.id === UserHeaders.USERID.value || managerIds.includes(leave.user.id))
            .sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime));
          managerIds = sortedData
            .map((leave) => leave.user.id)
            .filter(
              (id, index, self) =>
                id !== UserHeaders.USERID.value &&
                self.indexOf(id) === index
            );
        } else if (data && data.leaveDetails) {
          sortedData = data.leaveDetails
            .filter((leave) => leave.user.id === UserHeaders.USERID.value || managerIds.includes(leave.user.id))
            .sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime));
          managerIds = sortedData
            .map((leave) => leave.user.id)
            .filter(
              (id, index, self) =>
                id !== UserHeaders.USERID.value &&
                self.indexOf(id) === index
            );
        } else if (data) {
          sortedData = data
            .filter((leave) => leave.user.id === UserHeaders.USERID.value || managerIds.includes(leave.user.id))
            .sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime));
            managerIds = sortedData
              .map((leave) => leave.user.id)
              .filter(
                (id, index, self) =>
                id !== UserHeaders.USERID.value &&
                self.indexOf(id) === index
              );
        } else {
          logger.error("Invalid leave details structure:", data);
        }

        setApprovalData(sortedData);
       /*  logger.info("Sorted Data in Object:", sortedData); */
        setManagerIds(managerIds);
        /* logger.info("Manager IDs in Object:", managerIds); */
      } else if (!data || (data && data.leaveDetails === undefined)) {
        logger.warn("No leave request were found.");
      } else {
        logger.error(
        "Error fetching leave approval data:",
        response.status
        );
      }
    } catch (error) {
   //   logger.error("An error occurred while fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleApproveRejectSubmit = async (event) => {
    event.preventDefault();
    /* logger.info("Selected Leave:", selectedLeave); */
    try {
      await handleExpiredAccessToken(navigate, setAccessToken);
      const response = await fetch(
        `${API_BASE_URL}/edit-leave/${selectedLeave.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...getAuthHeaders(accessToken),
          },
          body: JSON.stringify({
            comments: comments,
            status: status,
            approvalId: UserHeaders.USERROLE.value,
          }),
        }
      );

      /* logger.info("API Response:", response); */

      if (response.ok) {
        setShowPopup(false);
        setSelectedLeave({});
        setComments("");
        setStatus("");
        setSuccessMessage("");
        setFileErrorMessage("");
        // Optionally, you can refetch data to update the table with the latest information
        fetchData();
      } else {
        logger.error("Error updating leave:", await response.json());
        setFileErrorMessage("");
      }
    } catch (error) {
      logger.error("An error occurred during the API call:", error.message);
      setFileErrorMessage("");
    }
  };

  // Logic to display items based on pagination
  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(approvalData.length / itemsPerPage);
  const currentItems = approvalData.slice(offset, offset + itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleButtonClick = (actionType, user) => {
    setComments("");
    setStatus(actionType.toUpperCase());
    setSelectedLeave(user);

    // Update approvalId based on user role ID
  if (hasApprovalRights || UserHeaders.USERROLE.value === 1 || UserHeaders.USERROLE.value===2) {
      /* logger.info("userRole:", UserHeaders.USERROLE.value); */
      user.approvalId = UserHeaders.USERROLE.value;
      setShowPopup(true);
    } else {
      user.approvalId = null;
      /* logger.info("userRole:", UserHeaders.USERROLE.value); */
    }

    //setShowPopup(true);
  };

  const handleFileUpload = async (e, user) => {
    if (e.target.files && e.target.files.length > 0) {
      const uploadedFile = e.target.files[0];

      const maxSizeInBytes = 10 * 1024 * 1024; // max size of uploading file is 10MB
      if (uploadedFile.size > maxSizeInBytes) {
        setFileErrorMessage((prevState) => ({
          ...prevState,
          [user.id]: "File size exceeds the limit of 10MB.",
        }));
        return;
      }
      const formData = new FormData();
      formData.append("medicalcertificate", uploadedFile);
      formData.append("id", user.id);

      try {
        setFileErrorMessage((prevState) => ({
          ...prevState,
          [user.id]: "",
        }));

        const response = await fetch(
          `${API_BASE_URL}/uploadmedicalcertificate/${UserHeaders.USERID.value}`,
          {
            method: "POST",
            headers: {
              ...getAuthHeaders(accessToken),
            },
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.code === 200) {
            setUploadedFiles((prevState) => ({
              ...prevState,
              [user.id]: "File uploaded successfully.",
            }));
            setTimeout(() => {
              setUploadedFiles((prevState) => ({ ...prevState, [user.id]: "" }));
            }, 3000);
          }
        } else if (response.status === 400) {
          const errorResponseData = await response.json();
          setFileErrorMessage((prevState) => ({
            ...prevState,
            [user.id]: errorResponseData.description || "Failed to upload medical certificate.",
          }));
        } else {
          setFileErrorMessage((prevState) => ({
            ...prevState,
            [user.id]: "Failed to upload medical certificate. Upload using jpg/png/pdf/jpeg format",
          }));
        }
      } catch (error) {
        logger.error("Error uploading file:", error.message);
        setFileErrorMessage((prevState) => ({
          ...prevState,
          [user.id]: "Failed to upload medical certificate.",
        }));
      }
    }
  };

const handleDownloadMedicalCertificate = async (id, filename, user) => {
  const { leaveTypeId, no_of_days } = user;
    if (no_of_days > 3) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/downloadmedicalcertificate/${id}`,
        {
          method: "POST",
        }
      );
      const responseData = await response.json();
      /* logger.info(responseData.message); */

      if (responseData.code !== 200) {
        throw new Error(responseData.message);
      }
      const fileUrl = responseData.message;
      // Function to download the image
      if (fileUrl != null) {
        const downloadImage = async (fileUrl) => {
          const imageResponse = await fetch(fileUrl);
          const blob = await imageResponse.blob();
          const objectUrl = URL.createObjectURL(blob);
          // Create a link element and click it to trigger the download
          const link = document.createElement("a");
          link.href = objectUrl;
          link.download = `medicalcertificate${id}`;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(objectUrl);
          document.body.removeChild(link);
        };
        // Download the image
        await downloadImage(fileUrl);
        /* logger.info("Image downloaded successfully"); */
      } else {
        setFileErrorMessage({
          ...fileErrorMessage,
          [id]: "File not uploaded yet. Please upload the medical certificate first.",
        });
        return;
      }
    } catch (error) {
      logger.error(
        "Failed to download Medical Certificate. Error:",
        error.message
      );
    }
    }
    else{
        setFileErrorMessage({
        ...fileErrorMessage,
        [id]: "Not allowed to download: Medical certificate download not applicable for this leave request",
        });
    }
  };

   function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }


  return (
    <div className="leave-request-container">
      <div>
        <table className="leave-request-table">
          <thead>
            <tr>
              <th>From Date</th>
              <th>To Date</th>
              <th>No of Days</th>
              <th>Leave Type</th>
              <th>Reason</th>
              <th>Comments</th>
              <th>Status</th>
              <th>Medical Certificate Upload</th>
              <th>Medical Certificate Download</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((user, index) => (
              <tr key={index}>
                <td>{formatDate(user.fromDate)}</td>
                <td>{formatDate(user.toDate)}</td>
                <td>{user.no_of_days}</td>
                <td>{capitalizeFirstLetter(user.leaveType.leave)}</td>
                <td>{user.reason}</td>
                <td>{user.comments}</td>
                <td>
                  {user.status && user.status.name !== null ? (
                    <div>{user.status.name}</div>
                  ) : (
                    <div>
                      {managerIds.includes(user.user.id) &&
                        user.status?.name !== "Approved" &&
                        user.status?.name !== "Rejected" && (
                          <div>
                            <button className="approve-button"
                              onClick={() => handleButtonClick("APPROVED", user)}>
                              Approve
                            </button>
                            <button className="reject-button"
                              onClick={() => handleButtonClick("REJECTED", user)}>
                              Reject
                            </button>
                          </div>
                        )}
                      {!managerIds.includes(user.user.id) &&
                        user.status?.name !== "Approved" &&
                        user.status?.name !== "Rejected" && <div>Pending</div>}
                    </div>
                  )}
                </td>
                {/* Render download button if status is Approved */}
                <td>
                  {user.leaveType.id === 2  && user.no_of_days >2 && user.status && user.status.name === "Approved" && (
                    <>
                      <label htmlFor={`file-upload-${user.id}`} className="leaverequest-upload-button">
                        Upload
                      </label>
                      <input id={`file-upload-${user.id}`} type="file" className="file-upload-input" onChange={(e) => handleFileUpload(e, user)}/>
                      {uploadedFiles[user.id] && ( <p className="upload-success-message">{uploadedFiles[user.id]}</p>)}
                      {fileErrorMessage[user.id] && ( <p className="upload-error-message">{fileErrorMessage[user.id]}</p>)}
                    </>
                  )}
                </td>
                <td>
                  {user.leaveType.id === 2 && user.no_of_days >2 && user.status && user.status.name === "Approved" && (
                    <button
                      className="leaverequest-download-button"
                      onClick={() =>
                        handleDownloadMedicalCertificate(
                          user.id,
                          user.medical_certificate,
                          user
                        )}>
                      Download
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
      <ReactPaginate
        previousLabel={ <>
          <FontAwesomeIcon icon={faArrowLeft} className="icon-left" />
          <span className="icon-pagination">Previous</span>
        </>}
        nextLabel={ <>
          <div className="next-container">
            <span className="icon-pagination">Next</span>
            <FontAwesomeIcon icon={faArrowRight} className="icon-right" />
          </div>
        </>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination-req"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          previousClassName={currentPage === 0 ? "disabled-button" : ""}
          nextClassName={currentPage === pageCount - 1 ? "disabled-button" : ""}
        />
      </div>

      <Popup
        open={showPopup}
        closeOnDocumentClick
        onClose={() => setShowPopup(false)}
        modal
        nested
        contentClassName="popup-content"
      >
        {(close) => (
          <div className="popup-container">
            <h3 className="popup-title">Update Leave Status</h3>
            <label className="popup-label">
              Comments:
              <input className="popup-input input-style" type="text" value={comments} onChange={(e) => setComments(e.target.value)}/>
            </label>
            <div className="popup-buttons">
              <button className="popup-submit" onClick={handleApproveRejectSubmit}>
                Submit
              </button>
              <button className="popup-close" onClick={close}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};
export default LeaveApproval;
