import React, { useState, useEffect } from "react";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import logger from './logs/logger';
import { API_BASE_URL } from './constant/apiConstants';
import { UserHeaders } from './constant/localStorageConstants';
import "./styles/UserProfile.css";
import { handleExpiredAccessToken } from './authUtils';
import { getAuthHeaders } from './authUtils';
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMobile, faIdCard, faPen } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { formatDate } from './Utils/ConvertDate.jsx';

/**
 * UserProfilePage
 * This page is intended for individual users.
 * It provides individual user detail based on Logged in user.
 */

const UserProfile = () => {
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(UserHeaders.ACCESSTOKEN.value);
  const [modalShow, setModalShow] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Fetch user details from the API
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        await handleExpiredAccessToken(navigate, setAccessToken);
        let userProfileUrl = `${API_BASE_URL}/profile`;
        // If id is provided in the URL, fetch user details by id
        if (id) {
          userProfileUrl = `${API_BASE_URL}/profile/${id}`;
        }

        const userProfileResponse = await fetch(userProfileUrl, {
          method: 'POST',
          headers: {
            [UserHeaders.USERID.headerKey]: UserHeaders.USERID.value,
            ...getAuthHeaders(accessToken),
          },
        });

        if (!userProfileResponse.ok) {
          logger.warn("User profile response not OK:", userProfileResponse);
          return;
        }

        const userProfileData = await userProfileResponse.json();
        setUserDetails(userProfileData);

        if (userProfileData && typeof userProfileData === 'object') {
          setUserDetails(userProfileData.message);
        } else {
          logger.error("User profile data is not a valid object:", userProfileData);
        }
      } catch (error) {
        logger.error("Error fetching user details:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchUserDetails();
  }, [id, accessToken]);

  // Function to handle editing user profile
  const handleEditUser = () => {
    const userId = id || UserHeaders.USERID.value;
    navigate("/editprofile", { state: { userId, previousPagePath: location.pathname } });
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  // Check if the user is authorized to view this profile
  const isHRorAdmin = UserHeaders.USERROLE.value === 1 || UserHeaders.USERROLE.value === 2;
  if (!isHRorAdmin && id) {
    navigate('/home');
    return null;
  }

  const backButtonHandler = () => {
    const currentPath = location.pathname;

    if (currentPath === "/profile") {
      navigate('/home');
    } else if (currentPath.startsWith("/profile/")) {
      if (userDetails && userDetails.employeeStatus) {
        if (userDetails.employeeStatus.name === "Permanent" || userDetails.employeeStatus.name === "Probation") {
          navigate('/allusers');
        } else if (userDetails.employeeStatus.name === "Ex_Employee") {
          navigate('/exemployees');
        } else {
          navigate('/home');
        }
      }
    }
  }

  return (
    <div className="container-fluid">
      <div className="user-profile-container">
        {/* profile photo with details container */}
        <div className="profile-container">
          <div className="row">
            <div className="col-md-2">
              <img src={userDetails.personalDetails?.profile_photo ? userDetails.personalDetails?.profile_photo : "/profileshadow.jpg"} className="profile-page-image img-fluid"/>
              <FontAwesomeIcon icon={faPen} className="edit-icon" onClick={() => setModalShow(true)} />
              <MyVerticallyCenteredModal show={modalShow} onHide={()=> setModalShow(false)} profilePhotoName = {userDetails.personalDetails?.profile_photo} userId={id ?? UserHeaders.USERID.value} />
              <div className="col-md-10">
                <h3 className = "user-profile-h3"> {userDetails.username} </h3>
                             <p className = "user-profile-p"> {userDetails.jobPosition.name}</p>
              </div>
              <br />
              <br />
              <div className="vertical-line"></div>
              <div className="icon-container">
                <div className="row">
                  <div className="col-sm-1 d-flex">
                    <FontAwesomeIcon icon={faIdCard} className="profile-icons" />
                    <p className="icons-text profile-info">{userDetails.employeeId}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1 d-flex">
                    <FontAwesomeIcon icon={faMobile} className="profile-icons"/>
                    <p className="icons-text phone-number">{userDetails.phoneNumber}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-1 d-flex">
                    <FontAwesomeIcon icon={faEnvelope} className="profile-icons"/>
                    <p className="icons-text profile-info">{userDetails.email}</p>
                  </div>
                </div>
              </div>
              <div className="button-container">
                <div className="col">
                  <button className="profilepage-edit-button" onClick={backButtonHandler}>Back</button>
                </div>
                <div className="col">
                  {(UserHeaders.USERROLE.value === 1 || UserHeaders.USERROLE.value === 2) && (
                    <button className="profilepage-edit-button" onClick={handleEditUser}>Edit</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Profile-details contains full login user details */}
        <div className="profile-details">
          <div className="column">
            <div>
              <strong>Employee ID</strong>{" "}
              <input type="text" className="textbox" value={userDetails.employeeId} readOnly />
            </div>
            <div>
              <strong>Last Name</strong> {" "}
              <input type="text" className="textbox" value={userDetails.lastName} readOnly />
            </div>
            <div>
              <strong>Job Position</strong> {" "}
              <input type="text" className="textbox" value={userDetails.jobPosition.name} readOnly />
            </div>
            <div>
              <strong>Date Of Birth</strong> {" "}
              <input type="text" className="textbox" value={formatDate(userDetails.personalDetails?.date_of_birth) } readOnly />
            </div>
            <div>
              <strong>Alternate MobileNo</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.alternatephoneNumber} readOnly />
            </div>
            <div>
              <strong>Work Location</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.country.name} readOnly />
            </div>
            <div>
              <strong>Address Line1</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.currentAddress} readOnly />
            </div>
            <div>
              <strong>Permanent State</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.permanentState} readOnly />
            </div>
            <div>
              <strong>Passport Number</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.passport} readOnly />
            </div>
          </div>
          <div className="column">
            <div>
              <strong>Employee Name</strong> {" "}
              <input type="text" className="textbox" value={userDetails.username} readOnly />
            </div>
            <div>
              <strong>Gender</strong> {" "}
              <input type="text" className="textbox" value={userDetails.gender?.name} readOnly />
            </div>
            <div>
              <strong>Department</strong> {" "}
              <input type="text" className="textbox" value={userDetails.department?.name} readOnly />
            </div>
            <div>
              <strong>Blood Group</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.bloodGroup.name} readOnly />
            </div>
            <div>
              <strong>Emergency Number</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.familyContact} readOnly />
            </div>
            <div>
              <strong>Current State</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.currentState} readOnly />
            </div>
            <div>
              <strong>Address Line2</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.addressLine2} readOnly />
            </div>
            <div>
              <strong>Permanent Postal Code</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.permanentPostalCode} readOnly />
            </div>
            <div>
              <strong>Pancard Number</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.pancard} readOnly />
            </div>
          </div>
          <div className="column">
            <div>
              <strong>First Name</strong> {" "}
              <input type="text" className="textbox" value={userDetails.firstName} readOnly />
            </div>
            <div>
              <strong> Company Email</strong> {" "}
              <input type="text" className="textbox" value={userDetails.email} readOnly />
            </div>
            <div>
              <strong>Role</strong> {" "}
              <input type="text" className="textbox" value={userDetails.role ? userDetails.role.name.toUpperCase() : ''} readOnly />
            </div>
            <div>
              <strong> Personal Email</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.personalemail} readOnly />
            </div>
            <div>
              <strong>Emergency Number Relation</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.familyRelation} readOnly />
            </div>
            <div>
              <strong>Current Postal Code</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.currentPostalCode} readOnly />
            </div>
            <div>
              <strong>HouseNo/Street/Landmark</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.permanentAddress} readOnly />
            </div>
            <div>
              <strong>Permanent Country</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.permanentCountry} readOnly />
            </div>
            <div>
              <strong>Aadhar Number</strong> {" "}
              <input type="text" className="textbox" value={userDetails.personalDetails?.aadhar} readOnly />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Update profile photo by clicking image
  function MyVerticallyCenteredModal(props) {
    const [file, setFile] = useState(null);
    const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append("profilephoto", file);
      try {
        const response = await fetch(
          `${API_BASE_URL}/uploadprofilephoto/${props.userId}`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          logger.info("Profile Image uploaded successfully");
          props.onHide();
          window.location.reload();
        } else {
          logger.error("Failed to upload Profile Image");
        }
      } catch (error) {
        logger.error("Error during fetch operation: ", error);
      }
    };

    const handleFileUpload = (e) => {
      const uploadedFile = e.target.files[0];
      setFile(uploadedFile);
    };

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Upload Profile Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={props.profilePhotoName ? props.profilePhotoName : "/profileshadow.jpg"} className="profile-photo-modal" />
          <Container>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={6} md={4} className="d-flex align-items-center justify-content-center" >
                  <input type="file" id="profilephoto" name="profilephoto" onChange={handleFileUpload} className="form-control" />
                </Col>
                <Col xs={6} md={4} className="d-flex align-items-center justify-content-center" >
                  <button type="submit" className="btn-profile">Upload</button>
                </Col>
                <Col xs={6} md={4} className="d-flex align-items-center justify-content-center" >
                  <Button onClick={props.onHide} className="btn btn-danger" style={{ width: "150px" }} >Close </Button>
                </Col>
              </Row>
            </form>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
};
export default UserProfile;
